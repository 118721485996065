import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import logoHorizontal from '../images/logo-horizontal.png';
import cart from '../images/shopping-basket.svg';
import account from '../images/account.svg';
import logout from '../images/logout-circle.svg';
import { digitalOptionCost, smallPrintCost, bigPrintCost } from '../utils/prices';

export default function Header({ signOut, loggedIn, smallPrintCounterSum, bigPrintCounterSum, digitalOptionCounterSum, onCheckoutClick }) {
  return (
    loggedIn ? (
      <header className='header'>
        <Link to='/'><img src={logo} className='header__logo' alt='Logo' /></Link>
        <div className='header__logo-horizontal-container'><Link to='/'><img src={logoHorizontal} className='header__logo-horizontal' alt='Logo' /></Link></div>
        <div className='header__group'>
          <Link className='header__link' to='/account'>
            <div className='header__link-content'>
              <div className='header__icon'><img src={account} alt='account'/></div>
              <div className='header__text'>Account</div>
            </div>
          </Link>

          <Link className='header__link' onClick={onCheckoutClick}>
            <div className='header__link-content'>
              <div className='header__icon'><img src={cart} alt='order'/></div>
              <div className='header__text'>
                Order: {digitalOptionCounterSum * digitalOptionCost + smallPrintCounterSum * smallPrintCost + bigPrintCounterSum * bigPrintCost} ₽
              </div>
            </div>
          </Link>

          <Link className='header__link' onClick={signOut} to='/sign-in'>
            <div className='header__link-content'>
              <div className='header__icon'><img src={logout} alt='logout'/></div>
              <div className='header__text'>Exit</div>
            </div>
          </Link>
        </div>
      </header>
    ) : (
      <header className='header header_noborder'></header>
    )
  );
}
