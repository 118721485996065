import React, {useRef} from 'react';
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';

import errorPicture from '../../images/cat-tears.png';
import './Account.css';

export default function Account({ handleDeleteUser, email }) {
  const form = useRef();
  function handleSubmit(e) {
    e.preventDefault();

    emailjs.sendForm('service_561hbko', 'template_g82a9bg', form.current, 'GrCFGQfjF4yWj0eR1')
      .then((result) => {
        console.log('ok');
      }, (error) => {
        console.log('not ok');
      });



    handleDeleteUser();
  }

  useEffect(() => {
    function handleClick(e) {
      if (e.target.classList.contains('popup_opened')) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const [isConfirmationPupupOpen, setIsConfirmationPopupOpen] = React.useState(false);

  function onClose() {
    setIsConfirmationPopupOpen(false)
  }

  function onOpen() {
    setIsConfirmationPopupOpen(true)
  }

  return (

    <section id='account' className='account'>
      <h2 className='account__title'>Your Account ({email})</h2>
      <ul className='account__project'>
        <li className='account__project-item'>
          <h3 className='account__project-subtitle'>Privacy</h3>
          <p className='account__project-text'>Since the website and your photos were made by the same person,
            you can be sure that none of your personal information was shared with any outside parties.
            Your information is well-protected within this private, single-person operated system.</p>
        </li>
        <li className='account__project-item'>
          <h3 className='account__project-subtitle'>You are in charge</h3>
          <p className='account__project-text'>You own your data.
            Should you choose to delete your account, please press the button below.
            Be advised, this process is irreversible and includes permanent removal of all your personal data —
            name, email and photos. Please consider this action carefully.
            </p></li>
      </ul>
      <div className='account__week'>
        <button className='account__week-subtitle_mod' onClick={onOpen}>Delete Profile</button>
      </div>


      <div className={`popup ${isConfirmationPupupOpen ? "popup_opened" : ""}`}>
        <div className='popup__tooltip-container'>
        <div className='popup__text-centered'>
            <img src={errorPicture} alt='warning' width="120px"></img>
            <h2>Are you sure?</h2>
            <button type='button' className='popup__close-btn' onClick={onClose} />
            <p>This can't be undone. All photos will be lost!</p>
            </div>
            <form ref={form} onSubmit={handleSubmit}>
            <input type="submit" onClick={handleSubmit} className='popup__submit-btn' value="Delete My Profile Forever" />
            <input type='hidden' name='email' value={email} />
          </form>
          
        </div>
      </div>
    </section>

  )
}