import React, { useEffect } from 'react';
import errorPicture from '../images/cat-oops.png';

export default function ConfirmationPopup({ isOpen, onClose, onDeleteCard, card }) {
  
  // Handle form submission
  function handleSubmit(e) {
    e.preventDefault();
    onDeleteCard(card);
    onClose();
  }

  // Handle keydown for Enter key
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        handleSubmit(e); // Trigger submit on Enter key press
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={`popup ${isOpen ? "popup_opened" : ""}`}>
      <div className='popup__tooltip-container'>
      <div className='popup__text-centered'>
        <img src={errorPicture} alt='warning' width='120px'></img>
        <h2 className='popup__tooltip-title'>Are you sure?</h2>
        <button type='button' className='popup__close-btn' onClick={onClose} />
        <p>This can't be undone</p>
        </div>
        <input type="submit" onClick={handleSubmit} className='popup__submit-btn' value="Delete Forever" />
      </div>
    </div>
  );
}
